<template>
  <div>
    <b-card>

      <!-- media -->
      <b-media no-body>
        <b-media-aside @click="$refs.refInputEl.$el.click()">
          <b-link>
            <b-img
              ref="previewEl"
              rounded
              :src="optionsLocal.avatar"
              height="200"
              widh="200"
            />
          </b-link>
          <!--/ avatar -->
        </b-media-aside>

        <b-media-body class="mt-75 ml-75">
          <!-- upload button -->
          <b-form-file
            ref="refInputEl"
            v-model="profileFile"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            @change="onFileChange"
          />
        </b-media-body>
      </b-media>
      <!--/ media -->
      <!-- form -->
      <b-form class="mt-2">
        <b-row>
          <b-col md="4">
            <b-form-group
              label="Họ và tên"
              label-for="account-username"
            >
              <b-form-input
                v-model="optionsLocal.username"
                placeholder="Họ và tên"
                name="Họ và tên"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="E-mail"
              label-for="account-e-mail"
            >
              <b-form-input
                v-model="optionsLocal.email"
                disabled
                name="email"
                placeholder="Email"
              />

            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Chế độ"
              label-for="account-e-mail"
            >
              <b-form-input
                v-model="optionsLocal.dark_mode"
                name="email"
                disabled
                placeholder="Email"
              />

            </b-form-group>
          </b-col>

          <!-- alert -->
          <b-col
            cols="12"
            class="mt-75"
          />
          <!--/ alert -->

        </b-row>
        <b-row>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Trạng thái"
              label-for="user-status"
            >
              <v-select
                v-model="optionsLocal.status"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="statusOptions"
                disabled
                :reduce="val => val.value"
                :clearable="false"
                input-id="user-status"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Quyền truy cập"
              label-for="user-role"
            >
              <v-select
                v-model="optionsLocal.role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="roleOptions"
                :reduce="val => val.value"
                disabled
                :clearable="false"
                input-id="user-role"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Ngày tham gia"
              label-for="account-username"
            >
              <b-form-input
                v-model="optionsLocal.created"
                placeholder="Họ và tên"
                disabled
                name="Họ và tên"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <b-card
        no-body
        class="border mt-1"
      >
        <b-card-header class="p-1">
          <b-card-title class="font-medium-2">
            <feather-icon
              icon="LockIcon"
              size="18"
            />
            <span class="align-middle ml-50">Permission</span>
          </b-card-title>
        </b-card-header>
        <b-table
          striped
          responsive
          class="mb-0"
          :items="permissionsData"
        >
          <template #cell(permission)="data">
            {{ data.value }}
          </template>
          <template #cell()="data">
            <b-form-checkbox
              disabled
              :checked="data.value"
            />
          </template>
        </b-table>
      </b-card>
      <b-row>
        <b-col cols="12">
          <b-button
            variant="danger"
            type="submit"
            class="btn-sm float-right"
            @click="updateProfile"
          >
            {{ $t('save_info') }}
          </b-button>
        </b-col>
      </b-row>

    </b-card>
    <b-card>
      <account-setting-password :user-id="optionsLocal.id" />
    </b-card>
  </div>
</template>

<script>
import {
  BFormFile, BButton, BForm, BCardTitle, BFormCheckbox, BCardHeader, BTable, BInputGroup, BInputGroupAppend, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { ref } from '@vue/composition-api'
import AccountSettingPassword from '@/views/pages/account-setting/AccountSettingPassword'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  components: {
    BButton,
    BCardTitle,
    BCardHeader,
    BFormCheckbox,
    BTable,
    BForm,
    BInputGroup,
    BInputGroupAppend,
    AccountSettingPassword,
    vSelect,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  props: {
    generalData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      optionsLocal: {
        id: null,
        avatar: null,
        username: null,
        fullName: null,
        email: null,
        status: true,
        role: null,
        created: null,
        dark_mode: null,
      },
      profileFile: null,
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
      statusOptions: [
        { label: 'Pending', value: 'pending' },
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
      roleOptions: [
        { label: 'Admin', value: 'admin' },
        { label: 'Author', value: 'author' },
        { label: 'Editor', value: 'editor' },
        { label: 'Maintainer', value: 'maintainer' },
        { label: 'Subscriber', value: 'subscriber' },
      ],
      permissionsData: [],
      RetypePassword: '',
      passwordValueOld: '',
      newPasswordValue: '',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem('userData'))
    if (userData) {
      const actionData = ['create', 'view', 'edit', 'delete']
      this.optionsLocal.username = userData.username
      this.optionsLocal.id = userData.id
      this.optionsLocal.avatar = userData.avatar
      this.optionsLocal.email = userData.email
      this.optionsLocal.dark_mode = userData.dark_mode
      this.optionsLocal.status = userData ? 'Hoạt động' : 'Tạm ngưng'
      this.optionsLocal.role = userData.role
      this.optionsLocal.created = userData.created
      const permistions = userData.ability
      const groupP = _.groupBy(permistions, 'subject')
      const permissionsData = []
      _.forEach(groupP, val => {
        _.forEach(val, p => {
          permissionsData.push({
            permission: p.subject,
            view: _.includes(actionData, 'view'),
            create: _.includes(actionData, 'create'),
            edit: _.includes(actionData, 'edit'),
            delete: _.includes(actionData, 'delete'),
          })
        })
      })
      this.permissionsData = _.uniqWith(permissionsData, _.isEqual)
    }
  },
  methods: {
    resetForm() {
      // this.optionsLocal = JSON.parse(JSON.stringify(this.generalData))
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.optionsLocal.avatar = URL.createObjectURL(file)
    },
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    updateProfile() {
      const formData = new FormData()
      formData.append('name', this.optionsLocal.username)
      if (this.profileFile) {
        formData.append('image', this.profileFile)
      }
      const url = `/users/${this.optionsLocal.id}`
      formData.append('_method', 'PUT')
      this.$http({
        method: 'post',
        url,
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })

      // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$bvToast.toast(this.$t('message.update_success'), {
            title: this.$t('message.notify'),
            icon: 'BellIcon',
            variant: 'success',
            solid: false,
          })
        })
      // eslint-disable-next-line no-unused-vars
        .catch(error => {
        }).finally(() => {
        })
    },
  },
}
</script>
<style lang="scss">
.input-group-text{
  border: 1px solid #d8d6de;
}
</style>
